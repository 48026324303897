<template>
<div id="get-quote">
    <NavBar />
    <QuoteComponent />
    <Testimonials />
    <TheFooter />
</div>
</template>

<script>
import NavBar from '@/components/NavBar.vue';
import TheFooter from '@/components/TheFooter.vue';
import QuoteComponent from '@/components/user/QuoteComponent.vue';
import Testimonials from '@/components/user/Testimonials.vue';

export default {
	components: {
        NavBar,
		TheFooter,
        Testimonials,
        QuoteComponent
	}
}
</script>

<style scoped>

</style>